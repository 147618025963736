@import "./../../styles/variables.scss";

.xgs-input {
  position: relative;

  &__field {
    width: 100%;

    height: 34px;
    border: 1px solid $gray4;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 8px;

    font-size: 14px;

    outline: none;
    transition: all 100ms;

    &:focus {
      box-shadow: 0 0 0 2pt rgba(52, 182, 255, 0.5);
    }

    &--w-icon {
      padding-right: 32px;
    }

    &__spinner {
      position: absolute;
      right: 6px;
      top: 8px;
    }
  }

  &__append-button {
    position: absolute;
    bottom: 10px;
    right: 9px;
    color: $gray4;
    font-size: 14px;
    cursor: pointer;
  }

  &__password-eye-icon {
    right: 10px;
  }
}
