@import "./../../../styles/mixins.scss";
@import "./../../../styles/variables.scss";

.xgs-enter-pod{
  
  &__form {
    overflow-x: auto;
    overflow-y: auto;
    height:  calc(100vh - 250px);
    width: calc(100vw - 280px);

    @include up-to(1025px) {
      width: 100%;
    }

    .xgs-select__indicator { // remove indicator
      display: none;
    }

    .xgs-labeled-input__validation-error {
      font-size: 11px;
    }
    
    &__controls {
      font-size: 30px;
      width: min(100%, 1120px);
      display: flex;
      gap: 20px;
      justify-content: space-between;

      position: relative;
      right: 0;

      &__list {
        display: flex;
        gap: 20px
      }
      svg {
        margin-right: $margin8;
      }
      &__push {
        color: $blue1;
      }

      &__pop {
        color: $gray3;

        &--disabled {
          color: $gray5;
        }
      }
    }

    &__row {
      display: flex;
      padding: 12px;
      gap: 14px;
      position: relative;
      width: min-content;
      
      @include up-to(850px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        padding-left: 22px;
      }

      &__serial {
        align-items: center;
        display: flex;
        
        @include up-to(850px) {
          position: absolute;
          top: 43%;
          left: 4px;
        }
      }

      @include up-to(560px) {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        width: 100%;

        :nth-child(9) {
          grid-column: span 2;
        }

        > * {
          width: 100% !important;
          grid-column: span 1;
        }
      }

      

      &:nth-child(even) {
        background-color: #eeeeee;
      }

      &:nth-child(odd) {
        background-color: #fafafa;
      }

      .xgs-select {
        &__menu {
          min-width: 100px;
        }
        &__control {
          font-size: 13px !important;
        }
        &__menu {
          font-size: 13px;
        }
      }

      label {
        font-size: 12px;
      }

      input {
        font-size: 13px;
      }

      &__field {
        position: relative;
        width: 80px;

        
        &--small {
          width: 60px;
        }

        &--large {
          width: 140px;
        }

        &__probill {
          width: 100px;
        }
      }
  
      &__date-item {
        position: relative;
        width: 200px;
        flex-shrink: 0;
    
        @include up-to(470px) {
          width: 100%;
        }
      }
    }
  }

  &__upload-field {
    width: 220px;
    display: block;
    @include up-to(560px) {
      grid-column: span 3;
      width: auto;
    }
  }

  &__upload {
    background-color: white;
    height: 34px;
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid $gray4;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      
      @include up-to(560px) {
        width: calc(100vw - 100px);
      }
    }
  }
}
